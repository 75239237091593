.visitStation,
.playerText,
.metadata,
.playPause {
  pointer-events: initial;
}

.player {
  filter: drop-shadow(1px 1px 2px #000) drop-shadow(-1px 1px 2px #000)
    drop-shadow(1px -1px 2px #000) drop-shadow(-1px -1px 2px #000)
    drop-shadow(2px 2px 2px #000) drop-shadow(-2px 2px 2px #000)
    drop-shadow(2px -2px 2px #000) drop-shadow(-2px -2px 2px #000);
}

.player {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-bottom: calc(1px + 0.5vmin);
}

.button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.playPause {
  padding: calc(5px + 1vmin);
  padding-left: calc(10px + 1vmin);
}

.playerText {
  padding-bottom: calc(1px + 0.5vmin);
  flex-grow: 1;
}

.metadata {
  color: #fff;
  font-size: calc(12px + 2vmin);
  margin-bottom: 3px;
}

.stationInfoContainer {
  display: flex;
  justify-content: space-between;
}

.link {
  text-decoration: none;
  color: #ff0;
}

.visitStation {
  color: #fff;
  font-size: calc(1px + 2vmin);
}

.codecInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  font-family: monospace;
  margin-right: calc(1px + 1vmin);
}

.codecItem {
  display: flex;
  justify-content: flex-end;
  font-size: calc(3px + 1vmin);
  margin-right: calc(1px + 1vmin);
}

svg {
  width: calc(40px + 2vmin);
}
