.about {
  pointer-events: initial;
  text-align: center;

  filter: drop-shadow(2px 2px 1px #000) drop-shadow(-2px 2px 1px #000)
    drop-shadow(2px -2px 1px #000) drop-shadow(-2px -2px 1px #000)
    drop-shadow(2px 2px 5px #000) drop-shadow(-2px 2px 5px #000)
    drop-shadow(2px -2px 5px #000) drop-shadow(-2px -2px 5px #000);
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerLink {
  text-decoration: none;
  color: yellow;
  font-size: calc(10px + 1vmin);
}

.mainLink {
  padding-top: calc(1px + 1vmin);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Courier New", Courier, monospace;
  font-size: calc(18px + 2vmin);
  text-decoration: none;
}

.subLink {
  font-size: calc(10px + 1vmin);
}

.description {
  color: white;
  font-size: calc(5px + 1vmin);
  padding-bottom: calc(5px + 0.1vmin);
}

.disclaimer {
  color: white;
  font-size: calc(2px + 1vmin);
  font-style: italic;
}
