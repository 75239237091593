.header,
.footer {
  display: flex;
  flex-direction: column;
  position: sticky;
  background: rgba(0, 0, 0, 0);
  width: 100%;
}

.header {
  top: 0;
  align-items: center;
  margin-bottom: calc(1px + 2vmin);
}

.footer {
  bottom: 0;
}

.visualizerSelector {
  position: absolute;
  right: 0;
}
